import React from 'react';

import Layout from 'components/layout';
import styled from 'styled-components/macro';
import SEO from 'components/SEO';
import { theme } from 'styled-tools';
import { isAndroid, isIOS, isMobileOnly } from 'react-device-detect';

const PageContent = styled.div`
  padding-top: 4rem;
  background-color: #000000;
`;

const MainContainer = styled.div`
  font-family: ${theme('fonts.title')};
  color: #ffffff;
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
  justify-content: center;
`;

const LeftContainer = styled.div`
  max-width: 580px;
  margin-right: 50px;
`;

const RightContainer = styled.div`
  max-width: 580px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImgRight = styled.img`
  max-height: 650px;
`;

const RightImageContainer = styled.div`
  max-width: 230px;
`;

const FormInputContainer = styled.div`
  margin-top: 10px;

  input {
    font-family: ${theme('fonts.title')};
    color: #ffffff;
    background-color: transparent;
    border-width: 0 0 1px 0;
    border-color: #ffffff;
    width: 100 %;
  }

  input:focus {
    outline-width: 0;
  }
`;

const PasswordConfirmationValidation = styled.div`
  span {
    color: #f45a46;
    font-size: 12px;
    padding-left: 10px;
  }
`;

const ContentHeader = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;

  span {
    font-size: 38px;
  }
`;

const ContentParagraph = styled.div`
  margin-bottom: 15px;

  span {
    font-size: 18px;
    line-height: 28px;
  }

  a {
    color: #ffbc6c;
  }
`;

const ResponseContainer = styled.div`
  color: #ffffff;
  justify-content: center;
  display: flex;
  padding: 10px;
  margin-top: 20px;

  span {
    word-break: break-word;
  }
`;

const FieldValidationText = styled.span`
  color: #f45a46;
  font-size: 12px;
  padding-left: 10px;
`;

const FormContainer = styled.div`
  margin-top: 60px;
`;

const FormSubmitButton = styled.button`
  font-family: ${theme('fonts.title')};
  font-size: 16px;
  font-weight: 400;
  color: #ffbc6c;
  background-color: #202830;
  border: 0;
  width: 100 %;
  margin-top: 20px;
  padding: 12px 6px;
`;

const validationText = React.createRef();
const passwordInput = React.createRef();
const passwordConfirmationInput = React.createRef();
const confirmationValidationText = React.createRef();
const passwordSubmitButton = React.createRef();
const responseContainer = React.createRef();
const responseText = React.createRef();

const PasswordResetPage = () => {
  return (
    <Layout>
      <SEO title='Password Reset' />
      <PageContent>
        <MainContainer>
          <LeftContainer>
            <ContentHeader>
              <span class='header-text'>Reset Your Password</span>
            </ContentHeader>
            <ContentParagraph>
              <span>
                Let's get you back in the app so you can keep investing.
              </span>
            </ContentParagraph>
            <ContentParagraph>
              <span>
                We want your account to be secure so if you didn't request a
                password reset, email us at{' '}
                <a href='mailto:help@dough.com'>help@dough.com</a>
              </span>
            </ContentParagraph>
            <FormContainer class='form-container'>
              <form>
                <FormInputContainer>
                  <input
                    autocomplete='new-password'
                    placeholder='Enter New Password'
                    ref={passwordInput}
                    type='password'
                    onBlur={(e) => {
                      validateField(e.currentTarget.value, validationText);
                    }}
                  />
                </FormInputContainer>
                <div class='password-validation'>
                  <FieldValidationText
                    ref={validationText}
                  ></FieldValidationText>
                </div>
                <FormInputContainer>
                  <input
                    autocomplete='new-password'
                    placeholder='Retype Password'
                    ref={passwordConfirmationInput}
                    type='password'
                    onBlur={(e) => {
                      validateField(
                        e.currentTarget.value,
                        confirmationValidationText
                      );
                    }}
                  />
                </FormInputContainer>
                <PasswordConfirmationValidation>
                  <FieldValidationText
                    ref={confirmationValidationText}
                  ></FieldValidationText>
                </PasswordConfirmationValidation>
              </form>
              <div>
                <FormSubmitButton
                  ref={passwordSubmitButton}
                  onClick={onPasswordResetSubmit}
                >
                  Reset Password
                </FormSubmitButton>
              </div>
              <ResponseContainer ref={responseContainer}>
                <span ref={responseText}></span>
              </ResponseContainer>
            </FormContainer>
          </LeftContainer>
          {!isMobileOnly && (
            <RightContainer>
              <RightImageContainer>
                <ImgRight src='https://info.dough.com/wp-content/uploads/2019/09/desktopPhoneImage.png' />
              </RightImageContainer>
            </RightContainer>
          )}
        </MainContainer>
      </PageContent>
    </Layout>
  );
};

export default PasswordResetPage;

function validateField(value, validationRef) {
  var requiredFieldText = '*Field is required';
  var text = validationRef.current;

  if (value === '') {
    text.innerText = requiredFieldText;
  } else {
    text.innerText = '';
  }
}

function displayStatusMessage(isSuccess, message) {
  var container = responseContainer.current;
  var text = responseText.current;
  var button = passwordSubmitButton.current;
  text.innerText = message.toUpperCase();
  if (isSuccess) {
    button.disabled = true;
  }
}

function parseErrorResponse(errorResponseString) {
  var errorMessages = [];
  if (errorResponseString != null && errorResponseString !== '') {
    var parsedError = JSON.parse(errorResponseString);
    if (parsedError.error) {
      if (parsedError.error.errors) {
        errorMessages = parsedError.error.errors.map(function (errorObj) {
          return errorObj.message;
        });
      } else if (parsedError.error.message) {
        errorMessages = [parsedError.error.message];
      }
    }
  }

  return errorMessages.join('\n');
}

let xhr;
if (typeof XMLHttpRequest !== `undefined`) {
  xhr = new XMLHttpRequest();
}
function onreadystatechange() {
  var responseContainer = responseText.current;
  if (xhr.readyState == 4 && xhr.status == 200) {
    var successText = 'Your password has been updated';
    displayStatusMessage(true, successText);
  } else {
    var failureText = parseErrorResponse(xhr.responseText);
    displayStatusMessage(false, failureText);
  }
}

function onPasswordResetSubmit() {
  var password = passwordInput.current.value;
  var passwordConfirmation = passwordConfirmationInput.current.value;

  if (password === '' || passwordConfirmation === '') {
    displayStatusMessage(false, 'Please fill out required fields');
    return null;
  }

  if (password !== passwordConfirmation) {
    displayStatusMessage(false, 'Passwords must match');
    return null;
  }

  var url = window.location.href;
  var paramsRaw = url.split('?');
  var params = paramsRaw[paramsRaw.length - 1].split('&');
  var token = null;

  for (var i = 0; i < params.length; i++) {
    var m = params[i].split('=');
    if (m[0] === 'token') {
      token = m[1];
    }
  }

  if (token == null) {
    displayStatusMessage(false, 'Invalid token');
    return null;
  }

  var params = 'reset-password-token=' + token + '&password=' + password;
  var passwordResetPayload = {
    ['reset-password-token']: token,
    password: password,
  };
  xhr.open('POST', 'https://api.dough.com/password', true);
  xhr.setRequestHeader('Content-type', 'application/json;charset=UTF-8"');
  xhr.onreadystatechange = onreadystatechange;
  xhr.send(JSON.stringify(passwordResetPayload));
}
